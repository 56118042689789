import React from 'react'
import tw, { styled } from 'twin.macro'

const VideoWrap = tw.div`w-full h-full`
const RatioWrap = tw.div`relative w-full`
const Box = styled.div`
	${tw`absolute max-w-[38%] lg:max-w-screen-xs z-10 right-16 lg:right-spacingXl-lg w-full cursor-pointer`}
	bottom: 180px;
	@media screen and (min-width: 1024px) {
		bottom: 20px;
	} ;
`

const getVideoId = (url) => {
	url = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
	return undefined !== url[2] ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0] // eslint-disable-line
}

const VideoBox = ({ data, onClick }) => {
	const videoId = getVideoId(data)
	return (
		<Box onClick={onClick}>
			<VideoWrap>
				<RatioWrap className='z-0 aspect-w-16 aspect-h-9'>
					<iframe title={`EMF Racing ${videoId}`} src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0`} frameBorder='0' allow='autoplay; encrypted-media' allowFullScreen></iframe>
				</RatioWrap>
			</VideoWrap>
		</Box>
	)
}
export default VideoBox
